new Vue({
  el: "#app",
  data() {
    return {
      progress: 0,
      audio: null,
      circleLeft: null,
      barWidth: null,
      duration: null,
      currentTime: null,
      isTimerPlaying: false,
      tracks: [], // Initialize as empty
      currentTrack: null,
      currentTrackIndex: 0,
      transitionName: null,
    };
  },
  methods: {
    play() {
      if (this.audio.paused) {
        this.audio.play();
        this.isTimerPlaying = true;
      } else {
        this.audio.pause();
        this.isTimerPlaying = false;
      }
    },

    generateTime() {
      let width = (100 / this.audio.duration) * this.audio.currentTime;
      this.barWidth = width + "%";
      this.circleLeft = width + "%";
      let durmin = Math.floor(this.audio.duration / 60);
      let dursec = Math.floor(this.audio.duration - durmin * 60);
      let curmin = Math.floor(this.audio.currentTime / 60);
      let cursec = Math.floor(this.audio.currentTime - curmin * 60);
      if (durmin < 10) {
        durmin = "0" + durmin;
      }
      if (dursec < 10) {
        dursec = "0" + dursec;
      }
      if (curmin < 10) {
        curmin = "0" + curmin;
      }
      if (cursec < 10) {
        cursec = "0" + cursec;
      }
      this.duration = durmin + ":" + dursec;
      this.currentTime = curmin + ":" + cursec;
    },
    updateBar(x) {
      let progressBar = this.$refs.progress;
      let newProgress = (x / progressBar.offsetWidth) * 100;

      if (newProgress > 100) {
        newProgress = 100;
      }

      if (newProgress < 0) {
        newProgress = 0;
      }

      this.progress = newProgress;

      const newTime = (this.audio.duration * this.progress) / 100;
      this.audio.currentTime = newTime;
      this.audio.play();
    },

    clickProgress(e) {
      const progressBar = this.$refs.progress;
      const clickPos = e.offsetX;
      const newProgress = (clickPos / progressBar.offsetWidth) * 100;

      if (newProgress > 100) {
        newProgress = 100;
      }

      if (newProgress < 0) {
        newProgress = 0;
      }

      this.progress = newProgress;

      const newTime = (this.audio.duration * this.progress) / 100;
      this.audio.currentTime = newTime;
      this.audio.play();
    },

    // updateBar(x) {
    //   let progress = this.$refs.progress;
    //   let maxduration = this.audio.duration;
    //   let position = x - progress.offsetLeft;
    //   let percentage = (100 * position) / progress.offsetWidth;
    //   if (percentage > 100) {
    //     percentage = 100;
    //   }
    //   if (percentage < 0) {
    //     percentage = 0;
    //   }
    //   this.barWidth = percentage + "%";
    //   this.circleLeft = percentage + "%";
    //   this.audio.currentTime = (maxduration * percentage) / 100;
    //   this.audio.play();
    // },
    // clickProgress(e) {
    //   const bar = this.$refs.progress;
    //   const clickX = e.offsetX;
    //   const newTime = (this.audio.duration * clickX) / bar.offsetWidth;
    //   this.audio.currentTime = newTime;
    //   this.audio.play();
    // },
    prevTrack() {
      this.transitionName = "scale-in";
      this.isShowCover = false;
      if (this.currentTrackIndex > 0) {
        this.currentTrackIndex--;
      } else {
        this.currentTrackIndex = this.tracks.length - 1;
      }
      this.currentTrack = this.tracks[this.currentTrackIndex];
      this.resetPlayer();
    },
    nextTrack() {
      this.transitionName = "scale-out";
      this.isShowCover = false;
      if (this.currentTrackIndex < this.tracks.length - 1) {
        this.currentTrackIndex++;
      } else {
        this.currentTrackIndex = 0;
      }
      this.currentTrack = this.tracks[this.currentTrackIndex];
      this.resetPlayer();
    },
    resetPlayer() {
      this.barWidth = 0;
      this.circleLeft = 0;
      this.audio.currentTime = 0;
      this.audio.src = this.currentTrack.source;
      setTimeout(() => {
        if (this.isTimerPlaying) {
          this.audio.play();
        } else {
          this.audio.pause();
        }
      }, 300);
    },
    favorite() {
      this.tracks[this.currentTrackIndex].favorited =
        !this.tracks[this.currentTrackIndex].favorited;
    },
  },
  created() {
    // import songs from "@/public/songs.json";
    let vm = this;
    // fetch("public/songs.json") // Adjust the path to your JSON file
    // fetch("songs.json") // Adjust the path to your JSON file
    fetch("/songs.json") // Adjust the path to your JSON file
      .then((response) => response.json())
      .then((data) => {
        vm.tracks = data;
        vm.currentTrack = vm.tracks[0]; // Set the first track as the current track
        vm.audio = new Audio();
        vm.audio.src = vm.currentTrack.source;
        vm.audio.ontimeupdate = function () {
          vm.generateTime();
        };
        vm.audio.onloadedmetadata = function () {
          vm.generateTime();
        };
        vm.audio.onended = function () {
          vm.nextTrack();
          this.isTimerPlaying = true;
        };
      })
      .catch((error) => console.error("Error fetching songs:", error));
  },
  // ... existing methods ...
});
